.pools
    padding-top: 130px
    display: flex
    flex-direction: column
    margin-bottom: 123px
    &__loader
        height: 300px
        min-height: auto
    &__tg
        display: flex
        align-items: center
        gap: 40px
        text-decoration: none
        justify-content: center
        margin-bottom: 117px
        &-icon
            width: 60px
            height: 60px
            filter: drop-shadow(0px 0px 20px rgba(49, 168, 220, 0.29))
        &-sub
            font-size: 24px
            line-height: 130%
            font-weight: 450
    &__desc
        margin-bottom: 20px
    &__items
        margin-bottom: 47px
        display: flex
        flex-wrap: wrap
        gap: 35px
    &__disclaimer
        & > span
            color: red
        font-weight: 400
        font-size: 14px
        line-height: 150%
        opacity: 0.4
        text-align: center
        max-width: 700px
        margin: 0 auto
        z-index: 0

@media (max-width: 1425px)
    .pools
        margin-bottom: 120px
        &__tg
            gap: 20px
            margin-bottom: 80px
            &-sub
                font-size: 20px
            &-icon
                width: 50px
                height: 50px
        &__items
            margin-bottom: 80px
            gap: 32px

@media (max-width: 719px)
    .pools
        padding-top: 80px
        margin-bottom: 80px
        &__tg
            display: none
        &__items
            flex-direction: column
            gap: 20px
            margin-bottom: 0
        &__disclaimer
            display: none
