.hero
    padding-top: 100px
    padding-bottom: 153px
    &__title
        text-align: center
        letter-spacing: 0.01em
    &__sub
        text-align: center
    &__numbers
        position: absolute
        left: 50%
        bottom: -50px
        transform: translateX(-50%)
        padding: 20px 34px
        background: #1B2029
        border: 1px solid rgba(255, 255, 255, 0.1)
        box-shadow: 0px 4px 62px rgba(0, 0, 0, 0.3)
        border-radius: 13px
        display: flex
        gap: 61px
    &__number
        display: flex
        flex-direction: column
        align-items: flex-start
        gap: 10px
    &__num
        font-weight: 450
        font-size: 34px
        line-height: 130%
    &__tg
        display: none
        text-decoration: none

@media screen and ( max-width: 1425px )
    .hero
        padding-top: 56px
        padding-bottom: 138px
        &__title
            margin-bottom: 40px

@media screen and ( max-width: 719px )
    .hero
        padding-bottom: 73px
        &__title
            text-align: left
            & > br:not(:first-of-type)
                display: none
        &__sub
            text-align: left
            & > br
                display: none
            margin-bottom: 40px
        &__numbers
            position: static
            border: none
            background: none
            width: 100%
            flex-wrap: wrap
            left: auto
            bottom: 0
            transform: none
            padding: 0
            justify-content: space-between
            gap: 20px
        &__number
            gap: 6px
        &__num
            font-size: 18px
        &__tg
            display: flex
            flex-direction: column
            align-items: center
            text-align: center
            position: fixed
            right: 16px
            z-index: 10
            bottom: 56px
            & > p
                background: #2FA6D9
                border-radius: 4px
                padding: 3px 3px 3px
                margin-bottom: 8px
                font-size: 11px
                font-weight: 450
                line-height: 130%
                &:first-child
                    border-bottom-left-radius: 0px
                    border-bottom-right-radius: 0px
                    z-index: 2
                    padding-bottom: 0
                    margin: 0

