.bottom
    &__download
        display: flex
        align-items: center
        gap: 10px
        opacity: 0.5
        transition: .3s
        font-weight: 400
        font-size: 16px
        line-height: 100%
        text-decoration: none
        cursor: pointer
        &-icon
            width: 24px
        &:hover
            opacity: 1
    &__buttons
        display: flex
        align-items: center
        gap: 30px
    &__box
        display: flex
        justify-content: space-between
        gap: 36px
    &__item
        height: 224px
        width: 100%
        padding: 40px
        background: linear-gradient(275.99deg, #17233E 5.25%, #141C31 92.46%)
        border-radius: 40px
        display: flex
        flex-direction: column
        justify-content: space-between

        overflow: hidden
        &:first-of-type
            &::before
                background: url('../../assets/img/back.png')
                position: absolute
                right: 0
                top: 0
                width: 100%
                height: 100%
                background-size: auto 100%
                background-position: 100% 100%
                background-repeat: no-repeat
                content: ''
                z-index: -4
                background-color: transparent
    &__title
        font-weight: 600
        font-size: 32px
        line-height: 120%
        letter-spacing: -0.01em

@media (max-width: 1425px)
    .bottom
        &__box
            flex-direction: column
        &__item
            padding: 30px
            height: auto
            gap: 20px

@media (max-width: 719px)
    .bottom
        &__buttons
            flex-direction: column
            align-items: flex-start
            gap: 20px
            & > *
                width: 100%
        &__title
            font-size: 20px
            text-align: center
