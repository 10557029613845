@import '../styles/vars'
@import '../styles/mixins'
    
.header-menu
    position: fixed
    width: 470px
    height: 100vh
    padding-top: 120px
    padding-left: 72px
    background: #090B10
    right: -100%
    top: 0
    transition: .3s
    &._visible
        right: 0
    &__items
        display: flex
        flex-direction: column
        gap: 30px
        height: 100%
    &__item
        font-size: 14px
        line-height: 100%
        text-decoration: none
    &__button
        display: none !important
    display: none

@media (max-width: 1425px) 
    .header-menu
        display: block

@media (max-width: 719px) 
    .header-menu
        width: 100vw
        padding-top: 120px
        padding-left: 72px
        padding-right: 72px
        padding-bottom: 41px
        &__items
            gap: 30px
            
        &__item
            font-size: 14px
        &__button
            display: flex !important
            margin-top: auto 
            width: 100%