

.rocket-loader
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    min-height: 650px
    padding-bottom: 10%
    &__inner
        display: flex
        justify-content: center
        transform: rotate(-45deg)
        align-items: center
    &__icon
        width: 50px
        animation: moveRocket 0.4s linear alternate infinite
        & > g > path
          &:nth-child(1)
            animation: moveFlames 0.08s linear alternate infinite
          &:nth-child(2)
            animation: moveFlames 0.06s linear alternate infinite
          &:nth-child(3)
            animation: moveFlames 0.05s linear alternate infinite



@media (max-width: 1425px)
    .rocket-loader
        min-height: 480px

@media (max-width: 719px)
    .rocket-loader
        min-height: 360px



@keyframes moveParticles
  100%
    background-position-x: -200rem

// @keyframes moveRocket
//   0%
//     transform: translate(-40%, 30%) rotate(45deg)
//   20%
//     transform: translate(-37%, 31%) rotate(45.5deg)
//   50%
//     transform: translate(-36%, 36%) rotate(45deg)
//   70%
//     transform: translate(-34%, 32%) rotate(45.5deg)
//   100%
//     transform: translate(-32%, 22%) rotate(45deg)

@keyframes flashing
  0%
    opacity: 1
  100%
    opacity: 0.4

@keyframes moveText
  0%
    transform: translate(-50%, 0%)
  20%
    transform: translate(-53%, 1%)
  50%
    transform: translate(-54%, 6%)
  70%
    transform: translate(-56%, 2%)
  100%
    transform: translate(-58%, -8%)

@keyframes moveFlames
  0%
    transform: translate(-2%, 2%)
  100%
    transform: translate(12%, -12%)

@keyframes moveRocket
  0%
    transform: translate(0%, 0%) rotate(45deg)
  20%
    transform: translate(3%, 1%) rotate(45.5deg)
  50%
    transform: translate(4%, 6%) rotate(45deg)
  70%
    transform: translate(6%, 2%) rotate(45.5deg)
  100%
    transform: translate(8%, -8%) rotate(45deg)
