@import '../styles/vars'
@import '../styles/mixins'
    
.footer
    padding-top: 200px
    padding-bottom: 200px
    &__inner
        display: flex
        align-items: flex-start
        justify-content: space-between
    &__socials
        display: flex
        flex-direction: column
        gap: 30px
        &-box
            display: grid
            grid-template-columns: repeat(4, 24px)
            grid-gap: 20px
    &__social
        align-items: center
        text-decoration: none
        opacity: 0.4
        display: flex
        flex-direction: column
        align-items: center
        cursor: pointer
        font-size: 15px
        &-icon
            width: 24px
        &:hover
            opacity: 1
    &__sub
        font-size: 16px
        line-height: 145%
        letter-spacing: -0.03em
        font-weight: 500
        text-decoration: none   
    &__doc  
        display: flex
        align-items: center
        opacity: 0.5
        text-decoration: none
        gap: 5px
        font-size: 15px
        &-icon
            width: 24px
        &:hover
            opacity: 1
    &__items
        display: flex
        flex-direction: column
        gap: 20px
    &__link
        display: flex
        align-items: center
        text-decoration: none
        gap: 10px
        opacity: 0.4
        font-size: 15px
        &-icon
            width: 10px
        &:hover
            opacity: 1

a.footer__sub
    &:hover
        color: lightblue !important

@media (max-width: 1425px) 
    .footer
        padding-top: 120px
        padding-bottom: 120px
        &__inner
            width: 100%
            display: grid
            grid-template-columns: auto auto auto
            grid-gap: 60px 120px
            justify-content: flex-start
        &__items
            gap: 20px
        &__sub
            font-size: 16px
        &__socials-box
            grid-gap: 20px
            grid-template-columns: repeat(4, 24px)
        &__social
            width: fit-content
            &-icon
                width: 24px
            gap: 5px
            font-size: 15px
        &__doc
            font-size: 15px
            gap: 5px
            &-icon
                width: 24px
        &__link
            &-icon
                width: 10px
            font-size: 15px
            gap: 5px

@media (max-width: 719px) 
    .footer
        padding-top: 80px
        padding-bottom: 80px
        &__inner
            display: flex
            flex-direction: column
            align-items: flex-start
            gap: 50px
        &__items, &__socials
            width: 100%
        &__items
            gap: 20px
        &__socials
            gap: 20px
        &__sub
            font-size: 16px
        &__doc, &__link
            font-size: 15px
            gap: 5px
        &__doc
            &-icon
                width: 24px
        &__link
            &-icon
                width: 10px    
        &__socials-box
            grid-gap: 20px
            grid-template-columns: repeat(4, 24px)
        &__social
            &-icon
                width: 24px
            gap: 5px
            font-size: 15px