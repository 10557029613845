@import './vars'
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;325;400;500;600;700;800;900&display=swap')
@import './mixins'
  
body
  font-family: "Inter", sans-serif


  
.app
  height: 100%
  background: $bg
  min-height: 100vh

*
  color: white

.title
  font-size: 62px
  line-height: 110%
  font-weight: 600
  margin-bottom: 40px

h3.title
  font-weight: 600
  font-size: 32px
  line-height: 120%


span.dark
  color: $secondary-text


p.sub
  font-size: 16px
  line-height: 130%
  font-weight: 400

p.desc
  letter-spacing: 0.04em
  text-transform: uppercase
  font-weight: 400
  font-size: 14px
  line-height: 120%
  color: rgba(255, 255, 255, 0.5)
  white-space: nowrap

.gradi
  background-image: $accent
  -webkit-background-clip: text
  color: transparent


section *
  z-index: 1

section
  margin: 0 auto
  width: fit-content
  min-width: 1312px
  max-width: 1312px

.swiper
  &-wrapper
    display: flex

@media (max-width: 1425px) 
  section
    width: 100%
    min-width: auto
    max-width: none
  .title
    font-size: 36px
  h3.title
    margin-bottom: 40px
  p.sub
    font-size: 15px
  .desc
    font-size: 14px

@media (max-width: 719px)
  .title
    font-size: 32px
  h3.title
    font-size: 24px
    margin-bottom: 30px
    text-align: left
  