.input
    display: flex
    color: black
    background: transparent
    outline: none
    border: 1px solid rgba(255, 255, 255, 0.4)
    border-radius: 13px
    height: 46px
    justify-content: center
    font-size: 16px
    padding-left: 54px
    
    color: white
    width: 100%
    transition: .3s
    &:focus 
        border-color: white
        &::-webkit-input-placeholder
            opacity: 0
    &-container
        width: 100%
        max-width: 323px
        &-img
            transition: .3s

            position: absolute
            width: 24px
            height: 24px
            left: 20px
            top: 50%
            transform: translateY(-50%)
            opacity: 0.4
            &.focused
                opacity: 1
            &.error
                opacity: 1
                & path
                    stroke: #DE4343

    &::-webkit-input-placeholder
        opacity: 0.6
        transition: .3s

    &.err
        border-color: #DE4343
        color: #DE4343
        &::-webkit-input-placeholder
            color: #DE4343
    &__error
        position: absolute
        top: calc(100% + 6px)
        left: 0
        font-size: 14px
        color: #DE4343
        letter-spacing: -0.01em


@media (max-width: 719px) 
    .input
        max-width: none

        &-container
            max-width: none
            &.err
                margin-bottom: 10px