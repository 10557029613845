.activity
    display: flex
    height: 100%
    width: 412px
    overflow: hidden
    border-radius: 40px
    background: #0A132C
    flex-direction: column
    cursor: pointer
    text-decoration: none
    transition: .3s
    &:hover
        transform: scale(1.03)
    &__frame
        height: 176px
        background-position: center
        background-size: cover
        background-repeat: no-repeat
        background-color: #1A2039
    &__content
        padding: 20px 36px
    &__title
        font-size: 20px
        line-height: 140%
        font-weight: 500
        margin-bottom: 10px
    &__desc
        font-size: 14px
        line-height: 140%
        color: #92929E

@media (max-width: 1425px)
    .activity
        width: 344px
        &__title
            font-size: 18px
        &__desc
            font-size: 14px
        &__content
            padding: 16px
        &__frame
            height: 176px

@media (max-width: 719px)
    .activity
        border-radius: 20px
        width: 291px
        &__title
            font-size: 16px
        &__desc
            font-size: 14px
        &__content
            padding: 16px
        &__frame
            height: 125px
