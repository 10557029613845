.pool
    width: 414px
    background: #1B2029
    overflow: visible
    border-radius: 13px
    &__networks
        display: flex
        gap: 5px
        align-items: center
        & > img
          width: 30px
    &__frame
        border-top-left-radius: 13px
        border-top-right-radius: 13px
        cursor: pointer
        display: flex
        flex-direction: column
        justify-content: space-between
        height: 184px
        padding: 20px
        background-position: center
        background-size: cover

        & *
            z-index: 2
        &::after
            border-top-left-radius: 13px
            border-top-right-radius: 13px
            z-index: 1
            content: ''
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            opacity: 0.8
            background-image: linear-gradient(0deg, #000917 22.28%, rgba(27, 41, 69, 0.25) 100%)
            transition: .3s
        &:hover::after
            opacity: 1
    &__top
        display: flex
        align-items: flex-start
        justify-content: space-between
    &__logo
        max-width: 40%
        max-height: 46px
    &__info
        display: flex
        flex-direction: column
        gap: 10px
    &__row
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
    &__desc
        font-size: 16px
        line-height: 100%
        font-weight: 450
        color: #FFFFFF50
        & > span
            color: white
    &__icon
        width: auto
        height: 24px
    &__bottom
        height: 43px
        display: flex
        align-items: center
        padding-left: 20px
        padding-right: 20px
        gap: 30px
        z-index: 0
    &__social
        opacity: 0.5
        transition: .3s
        text-decoration: none
        display: flex
        align-items: center
        gap: 6px
        &:hover
            opacity: 1
    &__currency
        display: flex
        align-items: center
        gap: 6px
        &-box
            display: flex
            gap: 10px
            justify-content: center

    &__exchange
        display: flex
        & > *
            &:nth-child(2)
                transform: translateX(-30%)
            &:nth-child(3)
                transform: translateX(-60%)
    &__vesting
        display: flex
        width: auto
        align-items: center
        gap: 6px
        font-weight: 450
        font-size: 16px
        line-height: 100%
        &-icon
            width: 16px
            height: 16px
        &-popup
            position: absolute
            top: 30px
            right: -20px
            width: auto
            min-width: 200px
            height: auto
            padding: 14px 20px
            opacity: 0
            z-index: 100
            transition: .3s
            background: #363E4D
            border: 1px solid rgba(255, 255, 255, 0.1)
            box-shadow: 0px 4px 62px rgba(0, 0, 0, 0.3)
            border-radius: 13px
            font-size: 14px
            line-height: 140%
            pointer-events: none
            &.opened
                opacity: 1


@media (max-width: 1425px)
    .pool
        width: calc(50% - 16px)
        &__logo
            max-height: 34px
        &__network
            width: 24px
            height: 24px
        &__currency
            font-size: 14px
        &__vesting
            font-size: 14px
        &__social
            font-size: 14px
            &-icon
                width: 16px
                height: 16px

@media (max-width: 719px)
    .pool
        width: 100%
        min-width: 330px
        &__frame
            padding: 16px
        &__vesting-popup
            top: -120px
            right: 5px
