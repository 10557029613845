.layout
  overflow-x: hidden
  height: 100%
  &__inner
    padding-top: 20px
    margin: 0 auto
    display: flex
    flex-direction: column
    height: 100%
    max-width: 1600px
    padding-left: 40px
    padding-right: 40px
  &__body
    flex: 1 0 auto
  &__footer
    flex: 0 0 auto

@media ( max-width: 1425px  )
  .layout
    &__inner
      width: 100%
      max-width: 720px
      padding-left: 0
      padding-right: 0

@media ( max-width: 719px  )
  .layout
    &__inner
      width: 100%
      max-width: 100%
      padding: 20px 16px 0px