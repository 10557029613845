.Modal
    background: #1B2029
    width: 552px
    height: auto
    border-radius: 13px
    overflow: hidden
.pool-modal
    &__close
        width: 24px
        height: 24px
        position: absolute
        top: 20px
        right: 20px
        cursor: pointer
    &__logo
        max-height: 51px
        max-width: 30%
    &__frame
        height: 184px
        background-position: center
        background-size: cover
        padding-left: 20px
        display: flex
        align-items: center
    &__content
        padding: 20px 20px 31px
    &__title
        font-weight: 600
        font-size: 24px
        line-height: 120%
        margin-bottom: 20px
    &__desc
        font-weight: 400
        font-size: 16px
        line-height: 130%
        margin-bottom: 20px
    &__button
        margin: 0 auto
        margin-bottom: 20px
    &__doclink, &__social
        display: flex
        align-items: center
        gap: 5px
        height: 34px
        padding-left: 10px
        padding-right: 10px
        background: rgba(255, 255, 255, 0.1)
        border-radius: 16px
        width: fit-content
        text-decoration: none
        & > *
            opacity: 0.5
            transition: .3s
        color: rgba(255, 255, 255, 0.5 )
        transition: .3s
        &:hover
            & > *
                opacity: 1
            color: white

    &__docs, &__socials
        display: flex
        gap: 6px
        flex-wrap: wrap
    &__socials
        margin-bottom: 10px
    &__docs
        margin-bottom: 20px
    &__disclaimer
        font-size: 13px
        line-height: 140%
        opacity: 0.5
        text-align: center
        & > span
            color: red


@media (max-width: 719px)
    .Modal
        width: calc(100% - 32px)
        max-height: 100vh
    .pool-modal
        width: 100%
        &__content
            padding-bottom: 20px
        &__frame
            height: 110px
        &__title
            font-size: 20px
            margin-bottom: 20px
        &__desc
            font-size: 14px
            margin-bottom: 20px
        &__button
            width: 100%
            margin-bottom: 20px
        &__doclink, &__social
            font-size: 13px
            height: 30px
            & img
                width: 21px
                height: 21px
        &__docs, &__socials
            gap: 10px
        &__disclaimer
            text-align: left
