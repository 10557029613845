.rays
    position: absolute
    bottom: 0
    left: 50%
    transform: translateX(-50%)
    width: 110vw
    height: auto
    z-index: 0
    pointer-events: none
    & path.ray
        &-1, &-2, &-3
            opacity: 0
    &.active path.ray
            &-1
                opacity: .7
                animation: lightOn .5s
            &-2
                opacity: .7
                animation: lightOn2 .9s
            &-3
                opacity: .7
                animation: lightOn3 .7s
@media (max-width: 560px) 
    .rays
        width: 200vw



@keyframes lightOn
    0%
        transform: translateY(200px) translateX(-300px)
        opacity: 0
    70%
        opacity: 1
    100%
        transform: translateX(0) translateY(0)
        opacity: .7

@keyframes lightOn2
    0%
        transform: translateY(400px) translateX(100px)
        opacity: 0
    70%
        opacity: 1
    100%
        transform: translateX(0) translateY(0)
        opacity: .7

@keyframes lightOn3
    0%
        transform: translateY(200px) translateX(300px)
        opacity: 0
    70%
        // transform: translateY(200px) translateX(300px)

        opacity: 1
    100%
        transform: translateX(0) translateY(0)
        opacity: .7