@import './mixins'
    
    
    
// $bg : linear-gradient(91.1deg, #0B1725 5%, #172435 98.48%)
$bg: #090D16
$accent: linear-gradient(90.45deg, #8BFBD8 -10.59%, #D8ECC6 45.05%, #F3DBF9 98.36%)
$blue: linear-gradient(180deg, #147FF6 0%, #165092 100%)
$secondary: rgba(255, 255, 255, 0.5)
$border: 1px solid rgba(255, 255, 255, 0.4)
$content-bg: #1B2029
$secondary-text: rgba(255, 255, 255, 0.2)
$radius: to-vw(13px)

$tablet: 860px
$mobile: 360px