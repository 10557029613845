.faq
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 120px
    &__title
        margin-bottom: 60px
    &__items
        display: flex
        flex-direction: column
        gap: 20px
    &__item
        display: flex
        flex-direction: column
        width: 830px
        padding: 30px
        border-radius: 13px
        background: #1B2029
        box-shadow: 0px 4px 62px rgba(0, 0, 0, 0.3)
    &__top
        display: flex
        align-items: center
        justify-content: space-between
        cursor: pointer
    &__body
        margin-top: 30px
    &__question
        font-weight: 450
        font-size: 24px
        line-height: 110%
    &__body
        font-weight: 400
        font-size: 16px
        line-height: 140%
        color: #B6B7BA
    &__icon
        width: 12px
        height: 7px
        transition: .3s
        &.opened
            transform: rotate(180deg)

@media (max-width: 1425px) 
    .faq
        margin-bottom: 120px
        &__item
            width: 100%
            padding-top: 24px
            padding-bottom: 24px

        &__items
            width: 100%
        &__question
            font-size: 20px
        &__body
            font-size: 14px
            margin-top: 14px

@media (max-width: 719px) 
    .faq
        align-items: flex-start
        margin-bottom: 80px
        &__item
            padding-top: 20px
            padding-bottom: 22px
        &__question
            font-size: 18px
        &__title
            text-align: left
            
        