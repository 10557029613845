.language-selector
  position: relative
  padding: 0
  cursor: pointer
  background: none
  border: none
  
  &__item
    position: relative
    height: 46px
    border: 1px solid rgba(255, 255, 255, 0.4)
    padding: 10px
    border-radius: 13px
    display: flex
    gap: 5px
    align-items: center
    font-weight: 600
    letter-spacing: 0.02em
    text-transform: capitalize
    transition: border-radius 0.25s
    overflow: hidden
    transition: .3s
    font-size: 15px
    color: rgba(255, 255, 255, 0.4)
    &:hover
      background: #1F2D3F
    &._open
      border-radius: 11px 11px 0px 0px
    &-icon
      width: 16px
      height: 16px
      border-radius: 50%
  &__popup
    pointer-events: none
    opacity: 0
    background: #0A132C
    position: absolute
    overflow: hidden
    top: 0
    left: 0
    border: 1px solid rgba(255, 255, 255, 0.4)
    border-radius: 11px
    transition: opacity 0.3s
    z-index: 1
    &._open
      pointer-events: auto
      opacity: 1
    & > div
      border-radius: 0
      border: none
      border-bottom: 1px solid  rgba(255, 255, 255, 0.4)
      &:first-child > img
        border: 1px solid #00BFFF
      &:last-child
        border-bottom: none

@media (max-width: 1425px) 
  .language-selector
    height: 46px
    &__item
      height: 46px

@media (max-width: 719px)
  .language-selector
    width: fit-content
    &__item
      padding: 8px 8px
      &._open
        border-radius: 11px
    &__popup
      & > div
        border: none
