.activities
    margin-bottom: 120px
    &__title
        text-align: center
        margin-bottom: 60px
    &__loader
        height: 400px
        min-height: auto

.swiper-slide
    width: 444px
    min-width: 444px
    opacity: 0
    transition: .1s
    &-next, &-active, &-next + &
        opacity: 1
.swiper
    overflow: hidden
    margin-left: -100px
    margin-right: -100px
    margin-top: -50px
    margin-bottom: -50px
    padding-top: 50px
    padding-bottom: 50px
    padding-left: 100px
    padding-right: 100px
.swiper-pagination
    display: flex
    margin: 0 auto
    width: 100%
    height: auto
    margin-top: 20px
    justify-content: center
    align-items: center
    gap: 6px

    &-bullet
        width: 8px
        height: 8px
        background: #1A2039
        border-radius: 50px
        transition: .3s
        margin: 0
        &-active
            background: #64DFFC

.swiper-button
    &-prev, &-next
        width: 30px
        height: 30px
        border-radius: 50%
        background-color: #1A2039
        position: absolute
        top: 50%
        transform: translateY(-50%)
        cursor: pointer
        transition: .3s
        background-position: 45% 50%
        background-size: auto 11px
        background-repeat: no-repeat

        &:hover
            background-color: #64DFFC
    &-disabled
        opacity: 0.5
        pointer-events: none
    &-next
        right: 50px
        background-image: url('../../assets/img/right.svg')
        background-position: 60% 50%

    &-prev
        left: 50px
        background-image: url('../../assets/img/left.svg')

@media (max-width: 1425px)
    .swiper-slide
        width: 374px
        min-width: 374px
    .swiper-button
        &-prev, &-next
            display: none
    .swiper-pagination
        display: none
    .swiper
        overflow: visible

@media (max-width: 719px)
    .activities
        margin-bottom: 80px
    .swiper-slide
        width: 307px
        opacity: 1
        min-width: 307px
