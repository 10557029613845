@import '../styles/vars'
@import '../styles/mixins'
    
.button
    height: 46px
    line-height: 100%
    display: flex
    justify-content: center
    align-items: center
    padding: 0 24px
    font-size: 16px
    gap: 13px
    white-space: nowrap
    border: 1px solid rgba(255, 255, 255, 0.4)
    border-radius: 13px
    background: none
    cursor: pointer
    overflow: hidden
    transition: .3s
    &._disabled
        opacity: 0.5
        pointer-events: none
    & > img
        width: 14px
    &::before
        width: 100%
        height: 100%
        position: absolute
        left: -100%
        content: ''
        background: white
        transition: .3s
        z-index: -1
    &:hover
        transition: 1s
        color: black
        border-color: transparent
        &::before
            left: 0

@media (max-width: 1425px) 
    .button
        gap: 13px 
        height: 46px 
        padding: 0 27px 
        border-radius: 13px 
        font-size: 14px 
        & > img
            width: 14px 
        &::before
            width: 100%
            height: 100%
            position: absolute
            left: -100%
            content: ''
            background: white
            transition: .3s
            z-index: -1
        &:hover
            transition: 1s
            color: inherit
            border: $border
            &::before
                display: none

@media (max-width: 719px) 
    .button
        font-size: 14px 
        height: 46px 
        padding: 0 27px 
        border-radius: 13px 