@import '../styles/mixins'
@import '../styles/vars'
    
.header
    z-index: 200
    &__inner
        display: flex
        align-items: center
        gap: 60px
    &__nav
        display: flex
        align-items: center
        gap: 40px
    &__logo
        width: 150px
        height: auto
        &-link
            display: flex
            justify-content: center
            align-items: center
    &__item
        line-height: 100%
        text-decoration: none
        font-size: 16px
        cursor: pointer
    &__buttons
        display: flex
        margin-left: auto
        gap: 20px
    &__menu
        &-button
            display: none !important

.menu-button
    height: 13px
    width: 14px
    &::before, &::after, & .middle
        width: 100%
        height: 1px
        content: ''
        left: 50%
        background: white
        position: absolute
        transform: translate(-50%, 0)
        transition: .3s
    &::befote
        top: 0
    &::after
        bottom: 0
    & .middle
        top: 50%
        transform: translate(-50%, -50%)

.opened.menu-button
    & .middle
        width: 0px
    &::before, &::after
        top: 50%
    &::after
        transform: translateY(-50%) rotate(-45deg) 
        left: 0
    &::before
        transform: translateY(-50%) rotate(45deg) 
        left: 0
    



@media (max-width: 1425px) 
    .header
        &__nav
            display: none
        &__buttons
            gap: 20px
            transition: .3s
            &.fx
                position: fixed
                right: 40px
                top: 20px
        &__logo
            width: 145px
        &__menu
            &-button
                display: flex !important
                width: 46px !important
                padding: 0 !important

@media (max-width: 719px) 
    .header
        margin: 0
        &__logo-link
            &.fx
                position: fixed
                left: 16px
                top: 21px
        &__button.submit
            display: none
        &__buttons.fx
            top: 21px
            right: 16px
        &__menu-button
            width: 46px !important
            & > img
                width: 40% !important
        &__logo
            width: 134px

    .menu-button
        height: 13px
        width: 14px

