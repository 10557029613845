$field: calc(50vw - 50%)


.projects
    margin-bottom: 120px
    &__loader
        min-height: auto
        height: 420px
    &__title
        text-align: center
        margin-bottom: 60px
    &__list
        display: flex
        justify-content: center
        gap: 35px
        flex: 1 0 1

@media (max-width: 1425px)

    .projects
        &__box
            width: 100vw
            overflow-x: scroll
            padding-left: $field
            padding-right: $field
            left: 50%
            transform: translateX(-50%)
            &::-webkit-scrollbar
                display: none
        &__list
            width: fit-content
            gap: 32px

@media (max-width: 719px)
    .projects
        margin-bottom: 80px
        &__title
            & > br
                display: none
        &__list
            gap: 16px
