.project
    display: flex
    flex-direction: column
    width: 412px
    height: 100%
    border-radius: 20px
    overflow: hidden
    background: #0A132C
    border: 1px solid #212B44

    &__frame
        height: 149px
        min-height: 149px
        width: 100%
        border-bottom: 1px solid #212B44
        background-position: center
        background-size: cover
    & ._row
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
    &__main
        padding: 20px
    &__title
        letter-spacing: 0.01em
        font-weight: 600
        font-size: 24px
        line-height: 120%
    &__top
        margin-bottom: 20px
    &__info
        margin-bottom: 10px
        & > *
            font-size: 16px
            line-height: 140%
            letter-spacing: 0.01em
    &__topic
        opacity: 0.4

    &__value
        font-weight: 600
    &__button
        width: 100%
        background: rgba(255, 255, 255, 0.07)
        border-radius: 14px
        outline: none
        border: none
        display: flex
        justify-content: center
        align-items: center
        padding-top: 12px
        padding-bottom: 12px
        font-weight: 500
        font-size: 16px
        line-height: 140%
        letter-spacing: 0.01em
        cursor: pointer
        transition: .3s
        &:hover
            opacity: 0.9
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5)
    &__opens
        color: rgba(250, 250, 250, 0.4)
        letter-spacing: 0.01em
        font-size: 14px
        line-height: 140%
    &__percent
        letter-spacing: 0.01em
        font-size: 14px
        line-height: 140%
        font-weight: 600
    &__progress
        width: 100%
        height: 8px
        border-radius: 32px
        background: rgba(255, 255, 255, 0.1)
        margin-top: 6px
        margin-bottom: 6px
        overflow: hidden
        &-line
            height: 100%
            border-radius: 32px
            background: #004DC4
    &__box
        display: flex
        align-items: center
        justify-content: space-between
    &__raise
        display: flex
        align-items: center
        color: rgba(250, 250, 250, 0.4)
        font-weight: 600
        font-size: 14px
        line-height: 140%
        letter-spacing: 0.01em
        &-left
            gap: 6px
        &-right
            gap: 6px
        margin-bottom: 10px
    &__currency
        width: 22px
        height: 22px
    &-fill__filled
        width: 100%
        display: flex
        align-items: center
        justify-content: center
        min-height: 72px
        font-weight: 300
        font-size: 16px
        line-height: 140%
        color: rgba(250, 250, 250, 0.4)

        text-align: center


@media (max-width: 1425px)
    .project
        width: 342px
        &__topic, &__value
            font-size: 14px
        &__title
            font-size: 20px
        &__frame
            height: 120px
            max-height: 120px
            min-height: 120px
        &__exchange
            width: 24px
            height: 24px
        &__opens, &__percent, &__raise, &-fill__filled
            font-size: 13px
        &-fill__filled
            min-height: 70px

@media (max-width: 719px)
    .project
        width: 290px
        &__frame
            height: 103px
            max-height: 104px
            min-height: 104px
